<template>
  <div class="global-wrapper">
    <header>
      <Header/>
    </header>
    <main>
      <Main/>  
    </main>
  </div>
</template>

<script>
import Header from './components/static/Header.vue'
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
  }
}
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-wrapper{
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  header{
    width: 100%;
    height: auto;
    color: white;
    background-color: lightseagreen;
  }
  main{
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 900px){
    .global-wrapper{
      overflow: auto;  
    }
  }
</style>
