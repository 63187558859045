<template>
  <div class="welcome-container">
    <h2>Welcome to Elthoro Events</h2>
    <div class="flex-welcome">
      <div class="welcome-col1">  
        <div class="imgSlider">
          <img src="../../assets/img\event.jpg" alt="">
        </div>
        <div class="text-area">
          <h3>About us:</h3>
          <p>
            Whether organizing a small meeting or orchestrating a large conference, event planning is a huge task! 
            Every event, no matter how simple or complex, requires detailed planning and organization. 
            From establishing an accurate budget to promoting your event, there are a number of components you should start to consider early on to make the process as stress-free as possible.
          </p>
          <p>
            While no two events are the same, and every event has varying goals, budgets, and audiences; 
            there are several steps you can take to jump-start the planning process, keep on track, and maximize your event’s success.
          </p>
          <p> 
            We at Elthoro Events pride ourselves in our ability to help organize ticket destribution for upcoming events.
          </p>
        </div>  
      </div>
      <div class="welcome-col2">
        <h3>New Upcoming Events:</h3>
      </div> 
    </div>
    
     
  </div>
  
</template>
<script>
  const axios = require('axios').default;
  export default {
    data() {
      return{

      }
    },
    methods:{
      
    }
  }
</script>
<style>
  .welcome-container{
    width: 100%;
    overflow: auto;
    padding: 20px;
    margin-bottom: 50px;
  }
  .welcome-container .flex-welcome{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .welcome-container h2{
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .welcome-container .welcome-col1{
    width: 65%;
  }
  .welcome-container .welcome-col1 h3, .welcome-container .welcome-col2 h3{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .text-area p{
    margin-bottom: 10px;
    font-size: 18px;
  }
  .welcome-container .welcome-col1 .imgSlider{
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }
  .welcome-container .welcome-col2{
    width: 30%;
    height: 600px;
    background-color: rgb(167, 233, 211);
    overflow: auto;
    padding: 20px;
  }
  .imgSlider img{
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 900px) {
    .welcome-container .welcome-col1{
      width: 100%;
    }
    .welcome-col2{
      display: none;
    }
  } 
  
</style>