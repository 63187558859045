import {createApp } from 'vue'
import App from './App.vue'
import '../public/assets/css/reset.css'
import '../public/assets/css/global.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'



const app = createApp(App)

app.mount('#app')