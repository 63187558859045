<template>
  <div class="container">
    <div v-if="showLogin">
      <label class="text-danger"></label>  
      <h3 >Login</h3> 
      <form>  
        <label>Username</label>  
        <input type="text" name="username" class="form-control" />
        <label>Password</label>  
        <input type="password" name="password" class="form-control" />
        <input type="submit" name="login" class="btn btn-info submit buttonColor" value="Login" />
      </form>
    </div>
    <div v-if="showCreateUser">
      <CreateUser/>
    </div>
    <div v-if="showForgottenPassword">
      <ForgottenPassword/>
    </div>   

    <div v-if="showLogin">
      <div class="login-buttons-flex">
        <a @click="toggleViewCreateUser">Create user</a>
        <a @click="toggleViewForgottenPassword">Forgotten password</a>
      </div>  
    </div>
    <div v-if="showCreateUser">
      <div class="center-link">
        <a @click="toggleViewLogin">Cancel</a> 
      </div>    
      
    </div>
    <div v-if="showForgottenPassword">
      <div class="center-link">
        <a @click="toggleViewLogin">Cancel</a> 
      </div>   
    </div>        
  </div>   
</template>
<script>
import CreateUser from './CreateUser.vue'
import ForgottenPassword from './ForgottenPassword.vue'
export default {
  name: 'Login',
  components: {
    CreateUser,
    ForgottenPassword
  },
  data() {
    return {
      showLogin: true,
      showCreateUser: false,
      showForgottenPassword: false,
    }
  }, 
  methods: {
    toggleViewLogin() {
      this.showForgottenPassword = false,
      this.showCreateUser = false,
      this.showLogin = !this.showLogin
    },
    toggleViewCreateUser() {
      this.showLogin = false,
      this.showForgottenPassword = false,
      this.showCreateUser = !this.showCreateUser
    },
    toggleViewForgottenPassword() {
      this.showLogin = false,
      this.showCreateUser = false,
      this.showForgottenPassword = !this.showForgottenPassword
    }
  }  
}
</script>
<style scoped>
  body{
    background-color: #f2f2f2;
  }

  .container{
    background-color: white;
    width: 30%;
    padding: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 150px auto;
  }

  .container h3{
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    margin-bottom: 25px;
  }
  .container p{
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .container input{
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .container .submit{
    display: block;
    text-align: center;
    width: 50%;
    font-weight: bold;
    margin: 35px auto;
    margin-bottom: 10px;
  }

  .login-buttons-flex{
    width:100%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .login-buttons-flex a{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;
    font-size: 14px;
  }
  .center-link {
    width:100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .center-link a {
    padding: 10px;
    margin: 0 auto;
  }
  .center-link:hover{
    color: red;
  }
  .buttonColor{
    background-color: lightseagreen;
    border: none;
  }
  .buttonColor:hover{
    background-color: rgb(26, 139, 134);
  }
</style>
