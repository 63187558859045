<template>
  <div class="profile-flex">
    <div class="profile-container">
      <h3>Profile</h3>
      <div>
        <h4>User Name:</h4>
        <p>{{user.user_name}}</p>

        <h4>Email</h4>
        <p>{{user.user_email}}</p>

        <button class="btn btn-primary button">Edit user info</button>
      </div>
    </div>
    <img src="../../assets/img/profile.jpg" alt="">  
  </div>
  
  
</template>
<script>
const axios = require('axios').default;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const username = urlParams.get('username');
console.log(username);
export default {
  data() {
    return{
      user:[],
      testUser: username,
    }
  },
  created() {
    this.fetchUserData(username);
  },
  mounted: async function(){
    this.fetchUserData(username);
  },
  methods:{
    async fetchUserData(username){
      await axios.get(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=person&id=${username}`)
      .then(response => {
        this.user = response.data[0];
      })
      .catch(error => {
        console.log(error);
      })
    },
  }
}

</script>
<style scoped>
.profile-container{
  width: 100%;
  padding: 20px;
}
.profile-container h3{
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
}
.profile-container h4{
  font-weight: bold;
  margin-bottom: 6px;
}
.profile-container p{
  margin-bottom: 30px;
}
.button{
  background-color: lightseagreen;
  border: none;
  margin-top: 20px;
}
.profile-flex{
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
}
.profile-flex img{
  margin-top: 50px;
  height: 200px;
  border-radius: 100px;
}

@media only screen and (max-width: 900px){
  .profile-flex img{
    display: none;  
  }
}
</style>