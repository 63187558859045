<template>
  <div class="flex-container">
    <div v-if="!showLogin" class="menu">
      <a id="frontPage" @click="toggleViewFrontpage">Welcome</a>
      <a id="eventPage" @click="toggleViewEventpage">Events</a>
      <a id="profilePage" @click="toggleViewUserProfile">Profil</a>
      <a id="logout" href="https://billetsystem.elthoro.dk/loginTest/logout.php">Logout</a>
    </div>
    <div class="content">
      <div v-if="showFrontpage">
        <Welcome/>
      </div>
      <div v-if="showEventList">
        <Events/>
      </div>
      <div v-if="showUserProfile">
        <Profile/>
      </div>
    </div>
  </div>
  
</template>
<script>

import Welcome from './Welcome.vue'
import Events from './Events.vue'
import Profile from './Profile.vue'
import Login from '../login/Login.vue'

export default {
  components: {
    Welcome,
    Events,
    Profile,
    Login,
  },
  data() {
    return {
      showFrontpage: true,
      showEventList: false,
      showUserProfile: false,
      showLogin: false,
    }
  },
  methods:{
    toggleViewFrontpage() {
      this.showEventList = false,
      this.showUserProfile = false,
      this.showFrontpage = true
    },
    toggleViewEventpage() {
      this.showFrontpage = false,
      this.showUserProfile = false,
      this.showEventList = true
    },
    toggleViewUserProfile() {
      this.showFrontpage = false,
      this.showEventList = false,
      this.showUserProfile = true
    },
  },
  
}
</script>
<style>
  .flex-container{
    width:100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    
  }
  .flex-container .menu{
    width: 17%;
    height: 100vh;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    background-color: rgb(167, 233, 211);
    height: 100%;
    padding-top: 10px;
  }
  .flex-container .content{
    width: 100%;
    display: block;
    padding: 20px;
    overflow: auto;
  }
  .menu a{
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    color: grey;
    text-decoration: none;
    padding: 20px; 
    border-bottom: 1px solid grey;
    cursor: pointer;
  }
  .menu a:last-of-type{
    border-bottom: 0px;
  }
  @media only screen and (max-width: 900px) {
    .flex-container{
      width:100%;
      display: block;
      justify-content: space-between;
      height: 100%;
    }
    .flex-container .content{
      width: 100%;
      display: block;
      padding: 20px;
      overflow: auto;
    }
    .flex-container .menu{
      flex-direction: row;
      width: 100%;
      max-width: 100%;
      justify-content: space-evenly;
      padding-top: 0px; 
    }
    .menu a{
      border: none;
      width: 20%;
      text-align: center;
      font-size: 18px;
    }
}
</style>