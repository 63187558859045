<template>
  <div v-if="eventList" class="newBtn">
    <button v-if="this.userInfo.user_admin === '1' "  type="button" class="button" @click="createPage()"> + New Event</button>
  </div>
  <div v-if="eventList" class="table-container">
    <table class="table table-hover">
      <thead class="blue">
        <tr>
          <th scope="col">Events</th>
          <th scope="col">Details</th>
          <th scope="col">Location</th>
          <th scope="col">Price</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{event.event_title}}</td>
          <td>{{event.event_detail}}</td>
          <td>{{event.event_place}}</td>
          <td class="tenWidth">$ {{event.event_price}}</td>
          <td class="twentyWidth">
            <div v-if="this.userInfo.user_admin === '1' " class="btn-group flex-action-buttons">
                <button type="button" id="button-width" class="btn btn-primary button" @click="getEventDetails(event.id); getEventDates(event.id)" ><i class="fas fa-book-reader"></i></button>        

                <button type="button" id="button-width" class="btn btn-primary button" @click="editEventDetails(event.id)" ><i class="fas fa-edit"></i></button>        
     
                <button type="button" id="button-width" class="btn btn-danger" @click="btnDelete(event.id)" ><img class="icon" src="../../assets\trash.png" alt=""></button>        
            </div>
            <div v-if="this.userInfo.user_admin === '0'">
              <button type="button" class="btn btn-primary button buttonText" @click="getEventDetails(event.id); getEventDates(event.id)" >See Details</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="eventDetails" class="detail-container">
    <h2>{{singleEvent.event_title}}</h2>
    <div class="flex-event-details">
      <div class="event-col">
        <div class="flex-subcolums">
          <div class="event-col1-subcol">
            <h3>Description:</h3>
            <p class="addBottomMarginFirstCol">{{singleEvent.event_detail}}</p>
            <h3>Location:</h3>
            <p class="addBottomMarginFirstCol">{{singleEvent.event_place}}</p>
            <h3>Price per person:</h3>
            <p class="addBottomMarginFirstCol">$ {{singleEvent.event_price}}</p>
          </div>
          <div class="event-col1-subcol" >
            <div class="flex-details-head">
              <h3>Date & time:</h3>
              <button v-if="this.userInfo.user_admin === '1'" type="button" class="button addDateBtn" @click="addDatesToEvent(singleEvent.id)"></button>
            </div>
            
            <div id="notify" class="eventDateWrapper">
              <div v-for="date in eventDates" :key="date.id">
                <div class="flex-date-time">
                  <div class="date-time-cols">
                    <p>On:</p>
                    <p>{{date.event_date}}</p>  
                  </div>
                  <div class="date-time-cols">
                    <p>At:</p>
                    <p>{{date.event_start}} - {{date.event_end}}</p> 
                  </div>
                </div>
                <p class="addBottomMargin">Available tickets: {{date.number_places}}</p>
              </div>
              <p>{{this.noDates}}</p> 
            </div>           
          </div>
        </div>
      </div>
      <div class="event-col">
        <img :src="this.imgURL" :alt="singleEvent.event_image" > 
        <button type="button" v-if="!hidePurchaseButton" class="buyTicketBtn" @click="ticketPage(this.singleEvent.id)">Buy Tickets</button>
        <a class="cancelAction" @click="cancelAction"><i class="fas fa-arrow-left"></i> Back </a> 
      </div>
    </div>
  </div>
  <div v-if="addEventDates">
    <h3 >Add Dates</h3><br />  
    <form @submit.prevent="createEventDates(singleEvent.id)" method="post" class="datesForm">
      <div class="flex-date-time">
        <div class="date-time-col">
          <label for="date">Date:</label>
          <div class="dateInput">
            <input type="text" name="day" v-model="day" placeholder="dd" maxlength="2" required>
            <p>/</p>
            <input type="text" name="month" v-model="month" placeholder="mm" maxlength="2" required>
            <p>/</p>
            <input type="text" name="year" v-model="year" placeholder="yyyy" maxlength="4" required>  
          </div>
        </div>
        <div class="date-time-col">
          <label for="startHour">Starts At:</label>
          <div class="hourInput" style="width: 110px; padding-top: 12px;">
            <input type="text" name="startHour" v-model="startHour" placeholder="hh" maxlength="2" required>
            <p>:</p>
            <input type="text" name="startMin" v-model="startMin" placeholder="mm" maxlength="2" required>
 
          </div>
        </div>
        <div class="date-time-col">
          <label for="endHour"> Ends At:</label>
          <div class="hourInput" style="width: 110px; padding-top: 12px;">
            <input type="text" name="endHour" v-model="endHour" placeholder="hh" required>
            <p>:</p>
            <input type="text" name="endMin" v-model="endMin" placeholder="mm" required>
          </div>
        </div>
        <div class="date-time-col">
          <div class="ticketsFree">
            <label for="number">Available Tickets:</label>
            <input style=" padding-top: 12px;" type="text" name="number" v-model="available" placeholder="enter number" required>  
          </div>
        </div>
        
      </div>

      <input type="submit" name="create" class="btn button submitNew" value="Create" /> 
      <div class="cancelCreateAction" @click="cancelAddDates">
        <i class="fas fa-arrow-left"></i> 
        Back 
      </div> 
    </form>
        
  </div>
  <div v-if="newEvent" class="newEvent">
    <h3 >Create Event</h3><br />  
    <form @submit.prevent="createNewEvent()" method="post" class="event-form">  
      <label for="title">Title:</label>
      <input type="text" id="newEventTitle" name="title" v-model="title" class="form-control" required/>

      <label for="detail">Event Description:</label> 
      <input type="text" id="newEventDetail" name="detail" v-model="detail" class="form-control" required/>

      <label for="place">Location:</label> 
      <input type="text" id="newEventLocation" name="place" v-model="place" class="form-control" required/>

      <label for="price">Price per Person:</label> 
      <input type="number" id="newEventPrice" name="price" v-model="price" class="form-control" required/>

      <label for="image">Image:</label> 
      <input type="text" id="newEventImage" name="image" v-model="image" class="form-control" required/>

      <input type="submit" name="create" class="btn button submitNew" value="Create" /> 
      <div class="cancelCreateAction" @click="cancelAction">
        <i class="fas fa-arrow-left"></i> 
        Back 
      </div> 
    </form>
  </div>
  <div v-if="editEvent" class="newEvent">
    <h3 >Edit Event</h3><br />  
    <form @submit.prevent="editEventInfo(singleEvent.id)" method="put" class="event-form" required>  
      <label for="title">Title:</label>
      <input type="text" name="title" :value="singleEvent.event_title" class="form-control" required />

      <label for="detail">Event Description:</label> 
      <input type="text" name="detail" :value="singleEvent.event_detail" class="form-control" required/>

      <label for="place">Location:</label> 
      <input type="text" name="place" :value="singleEvent.event_place" class="form-control" required/>

      <label for="price">Price per Person:</label> 
      <input type="number" name="price" :value="singleEvent.event_price" class="form-control" required/>

      <label for="image">Image:</label> 
      <input type="text" name="image" :value="singleEvent.event_image" class="form-control" required/>

      <input type="submit" name="create" class="btn button submitNew" value="Edit" />
      <div class="cancelCreateAction" @click="cancelAddDates">
        <i class="fas fa-arrow-left"></i> 
        Back 
      </div>   
    </form>
  </div>
  <div v-if="buyTicketPage" class="buyTicketForEvent">
    <h4 >Buy Ticket - Pick a date</h4>
    <form @submit="purchaseTicket(singleEvent.id)" action="https://billetsystem.elthoro.dk/FrontArea/funktioner/getTicket.php" target="_blank" method="post" class="ticket-form ">
        <div class="event-col1-subcol new-width">
          <h3>Description:</h3>
          <p class="addBottomMargin">{{singleEvent.event_detail}}</p>
          <h3>Location:</h3>
          <p class="addBottomMargin">{{singleEvent.event_place}}</p>
          <h3>Price per person:</h3>
          <p class="addBottomMargin">{{singleEvent.event_price}} DKK</p>
        </div>
        <div class="date-picker">
          <div v-if="dateOption">
            <div class="flex-date-picker" v-for="date in eventDates" :key="date.id">
              <input type="radio" name="eventdateid" :value="date.id" v-model="pickedDate">
              <label for="eventdateid">{{date.event_date}} at {{date.event_start}}-{{date.event_end}}</label>
            </div> 
          </div> 
          <button v-if="dateOption" name="create" class="btn button center-button" @click="pickPaymentMethod()">Pick payment method</button>
          <div v-if="paymentOption">
            <div class="flex-date-picker">
              <input type="radio" name="pay" class="payment" value="dankort" checked>
              <label for="">Dankort</label> 
            </div>   
            <div class="flex-date-picker">      
              <input type="radio" name="pay" class="payment" value="visakort"> 
              <label for="">Visakort</label>
            </div>
            <div class="flex-date-picker">      
              <input type="radio" name="pay" class="payment" value="paypal"> 
              <label for="">PayPal</label>
            </div>
            <div class="flex-date-picker" style="margin-bottom: 20px;">      
              <input type="radio" name="pay" class="payment" value="mobilepay"> 
              <label for="">Mobilepay</label>
            </div>
            <div class="flex-date-picker">
              <input type="hidden" name="dateid" v-model="this.pickedDate">
              <input type="hidden" name="userid" v-model="this.eventuserid">
              <input type="hidden" name="price" v-model="this.singleEvent">  
            </div>
            <a @click="choseAnotherDate" style="color: blue; text-decoration: underline; padding: 5px; cursor: pointer" >Change choosen Date</a>
          </div>          
        </div> 
      <input v-if="paymentOption" type="submit" name="create" class="btn button center-button"  style="width: 225px;" value="Buy Ticket" />
      <div class="cancelCreateAction" @click="cancelAddDates" style="width: 225px;">
        <i class="fas fa-arrow-left"></i> 
        Cancel Purchase 
      </div>    
    </form>
  </div>
</template>
<script>
const axios = require('axios').default;
import UserData from './Profile.vue'

export default {
  data() {
    return {
      user: [],
      userInfo: [],
      noDates: '',
      imgURL: '',
      dateOption: false,
      paymentOption: false,
      pickedDate: 0,
      eventList: true,
      eventDetails: false,
      newEvent: false,
      editEvent: false,
      buyTicketPage: false,
      addEventDates: false,
      hidePurchaseButton: false,

      events: [],
      singleEvent: {
        event_id: '',
        event_title: '',
        event_detail: '',
        event_place: '',
        event_price: '',
        event_image: 'error.jpg',
      },
      title: "",
      detail: "",
      place: "",
      price: "",
      image: "",
      eventDates: [],
      
      day: '',
      month: '',
      year: '',
      startHour: '',
      startMin: '',

      endHour: '',
      endMin: '',
      available: '',

      allowed: 1,
      ticketprice: 13.00,
      eventdateid: 0,
      eventuserid: 1,
    }
  },
  created() {
    const username = UserData.data().testUser;
    this.user = username;
  },
  mounted: async function(){
    this.eventDetails = false;
    this.newEvent = false;
    var element = document.getElementById('eventPage'); // grab a reference to your element
    element.addEventListener("click", () => {
      this.eventList = true;
      this.eventDetails = false;
      this.newEvent = false;
    });
    this.setUserData();
    this.getAllEvents();
  },
  methods: {
    async setUserData(username){
      await axios.get(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=person&id=${this.user}`)
      .then(response => {
        this.userInfo = response.data[0];
      })
      .catch(error => {
        console.log(error);
      })
    },
    async getAllEvents(){
      this.eventList = true;
      this.eventDetails = false,
      this.newEvent = false;
      this.dateOption = false; 
      this.buyTicketPage = false;
      this.paymentOption = false,
      this.pickedDate= 0;
      this.editEvent = false;
      this.buyTicketPage = false;
      this.addEventDates = false;

      await axios.get('https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event')
      .then(response => {
        this.events = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    async btnDelete(id) {
      let text = "Are you sure you wish to Delete!";
      if (confirm(text) == true) {
        await fetch(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event&id=${id}`, {
          method: 'DELETE',
        })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        this.getAllEvents();
      } else {
        return;
      }    
    },
    async createNewEvent(){
      const eventPrice = document.getElementById('newEventPrice');

      if (!isNaN(eventPrice)){
        alert("Price is not a Number. Please enter a number");
        return;
      }
      var price = Number(this.price);
      const payload = {
        title: this.title,
        detail: this.detail,
        place: this.place,
        price: price,
        image: this.image,
      };
      await fetch(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      .then((response) => {
        this.eventList = true;
        this.newEvent = false;
        this.eventDetails = false;
        return response.json();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
      this.getAllEvents(); 
    },
    async editEventInfo(id){
      var price = Number(this.singleEvent.event_price);
      const payload = {
        title: this.singleEvent.event_title,
        detail: this.singleEvent.event_detail,
        place: this.singleEvent.event_place,
        price: price,
        image: this.singleEvent.event_image,
      };

      await fetch(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event&id=${id}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
      })
      .then((response) => {
        this.eventList = true;
        this.newEvent = false;
        this.eventDetails = false;
        return response.json();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
      this.getAllEvents();
    },
    async createPage(){
      this.eventList = false;
      this.newEvent = true;
      this.eventDetails = false;
      this.buyTicketPage = false;
      this.dateOption = false;
    },
    async getEventDetails(id){
      this.eventList = false;
      this.newEvent = false;
      this.buyTicketPage = false;
      this.dateOption = false;
      this.eventDetails = true;

      axios.get(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event&id=${id}`)
      .then(response => {
        this.singleEvent = response.data[0];
        const imgPath = this.singleEvent.event_image;
        this.imgURL = "https://billetsystem.elthoro.dk/FrontArea/apien/" + imgPath;
      })
      .catch(error => {
        console.log(error);
      })
    },
    async getEventDates(id){
      this.noDates = '';
      this.hidePurchaseButton = false;
      axios.get(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=date&id=${id}`)
      .then(response => {
        this.eventDates = response.data;
        if(this.eventDates.length === 0){
          this.noDates = "No available dates"
          this.hidePurchaseButton = true;
        }
      })
      .catch(error => {
        console.log(error);
      })
    },
    async createEventDates(id){
      const day = this.day;
      const month = this.month;
      const year = this.year;
      const date = `${day}/${month}/${year}`;

      const startHour = this.startHour;
      const startMIn = this.startMin;
      const startTime = startHour + "." + startMIn;

      const endHour = this.endHour;
      const endMIn = this.endMin;
      const endTime = endHour + "." + endMIn;

      const eventID = id;
      const seats = this.available;
      const payload = {
        date: date,
        number: seats,
        start: startTime,
        end: endTime,
        eventid: eventID,
      };

      await fetch(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=date`, {
        method: 'POST',
        body: JSON.stringify(payload)
      })
      .then((response) => {
        this.eventDetails = false;
        this.addEventDates = false; 
        return response.json();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
      this.getEventDates(); 
    },
    async addDatesToEvent(id){
      this.eventDetails = false;
      this.addEventDates = true;    
    },
    async editEventDetails(id){
      axios.get(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=event&id=${id}`)
      .then(response => {
        this.singleEvent = response.data[0];
        this.eventList = false;
        this.editEvent = true;
      })
      .catch(error => {
        console.log(error);
      })
    },
    cancelAction() {
      this.eventList = true;
      this.eventDetails = false;
      this.newEvent = false;
      this.editEvent =false;
      this.buyTicketPage = false;
    },
    cancelAddDates() {
      this.eventList = false,
      this.eventDetails = true,
      this.newEvent = false;
      this.buyTicketPage = false;
      this.addEventDates = false
      
    },
    async ticketPage(id){
      this.eventDetails = false;
      this.buyTicketPage = true;
      this.dateOption = true;

      var price = Number(this.price);
      const payload = {
        allowed: 1,
        price: this.ticketprice,
        eventdateid: this.eventdateid,
        eventuserid: this.eventuserid,
      };
    },

    async pickPaymentMethod(){
      this.dateOption = false;
      this.paymentOption = true;
    },
    async choseAnotherDate(){
      this.dateOption = true;
      this.paymentOption = false;
    },

    async purchaseTicket(id){
      var userId = Number(id);
      var eventId = Number(this.pickedDate);
      var price = Number(this.singleEvent.event_price);
      const payload = {
        allowed: true,
        price: price,
        eventdateid: eventId,
        eventuserid: userId
      };

      await fetch(`https://billetsystem.elthoro.dk/FrontArea/apien/?pass=ticket`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      .then((response) => {
        this.eventList = true;
        this.newEvent = false;
        this.eventDetails = false;
        this.buyTicketPage = false;
        return response.json();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
      
    },
  }
}

</script>

<style scoped>
  h2{
    width: 100%;
    font-weight: bold;
    font-size: 25px;
    border: 1px solid grey;
    border-bottom: 0px;
    padding: 15px;
    background-color: lightseagreen;
    margin: 0px;
  }
  h3{
    font-weight: bold;
    font-size: 20px;
    padding-left: 15px;
  }
  .newEvent h4, .buyTicketForEvent h4{
    font-weight: bold;
    font-size: 20px;
    padding-left: 15px;
    margin-bottom: 20px;
  }
  .flex-ticket{
    width: 100%;
    display: flex;
  }
  .buyTicketForEvent{
    width:100%;
  }
  p{
    padding-left: 15px;
    margin-top: 5px;
  }
  .table-container{
    margin: 20px;
    margin-bottom: 100px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid grey;
  }
  .detail-container{
    margin: 20px;
    margin-bottom: 50px;
  }
  .table{
    margin: 0px;
  }
  .icon{
    width: 18px;
  }
  .tenWidth{
    width: 10%;
  }
  .twentyWidth{
    width: 170px;
  }
  #button-width{
    border-radius: 5px;
    max-width: 45px;
  }  
  .blue{
    font-weight: bold;
    font-size: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: white;
    background-color: lightseagreen;
    border-radius: 5px 5px 0px 0px;
  }
  .button{
    background-color: lightseagreen;
    border: none;
  }
  .buttonText{
    font-size: 20px;
    font-weight: bold;
  }
  .button:hover, .buyTicketBtn:hover{
    background-color:rgb(26, 139, 134);;
  }
  tr:nth-child(even){
    background-color: lightgray;
  }
  .flex-action-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .flex-event-details{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid grey;
  }
  .event-col{
    width: 50%;
  }
  .event-col:first-of-type{
    border-right: 1px solid grey;
    width: 55%;
    padding-top: 15px;
  }
  .event-col:last-of-type{
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 45%;
  }
  .event-col img{
    background-color: lightgray;
    width: 100%;
    height: 350px;
  }
  .buyTicketBtn{
    width: 100%;
    margin-top: 30px; 
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: lightseagreen;
    color: white;
    font-weight: bold;
    font-size: 25px;
    border: 1px solid grey;
  }
  .flex-subcolums{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .event-col1-subcol{
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .event-col1-subcol:first-of-type{
    padding-top: 10px;
    width: 45%;
  }
  .event-col1-subcol:last-of-type{
    padding-right: 15px;
  }
  .event-col1-subcol:last-of-type h3{
    padding: 0px;
    width: 120px;
  }
  .event-col1-subcol .addBottomMargin{
    margin-bottom: 20px; 
  }

  .event-col1-subcol .addBottomMarginFirstCol{
    margin-bottom: 30px; 
  }

  .flex-end{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .cancelCreateAction{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-right: 50px;
    color: red;
    border: 3px solid red;
    border-radius: 5px;
    padding: 10px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .cancelAction{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-right: 15px;
    color: red;
    font-weight: bold;
    font-size: 20px;
    border: 3px solid red;
    padding: 15px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .cancelCreateAction i{
    padding-right: 5px;
    padding-top: 5px;
  }
  .cancelCreateAction:hover{
    background-color: red;
    color: white;
  }
  .cancelAction:hover{
    background-color: red;
    color: white;
  }
  .cancelAction i{
    padding-top: 2.5px;
    padding-right: 5px;
  }
  .newBtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
  }

  .newBtn button{
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .submitNew{
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
  }
  .center-button{
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin: 30px auto;
  }
  .newEvent{
    width: 100%;
  }
  .event-form, .datesForm{
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
    padding-top: 0px;
    font-size: 18px;
  }
  .event-form input, .datesForm input{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .event-form label{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    font-weight: bold;
  }
  .event-form label:first-of-type{
    margin-top: 0px;
  }
  .new-width{
    width: 30%;
  }
  .date-picker{
    border: 1px solid grey;
    padding: 20px;
    padding-left: 15px;
    border-radius: 5px;
    margin-top: 30px;
    background-color: rgb(235, 235, 235);
    overflow: auto;
  }
  .date-picker input{
    margin: 10px;
  } 
  input[type='radio'] { 
    transform: scale(2); 
  }
  .flex-date-picker{
    width: 250px;
  }
  .flex-details-head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .flex-details-head h3{
    margin-top: 10px;
  }
  .addDateBtn:before{
    content: '+ Add Dates';
  }
  .addDateBtn{
    content: '+ Add Dates';
    padding: 10px;
    width: 120px;
    color: white;
    font-weight: bold;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .eventDateWrapper{
    background-color: rgb(240, 238, 238);
    width: 100%;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    height: 470px;
    overflow: auto;
  }
  .eventDateWrapper p{
    padding: 0px;
  }
  .dateInput{
    width: 160px;
    display: flex;
    border: 1px solid grey;
    font-size: 20px;
    padding: 10px;
  }
  .dateInput input{
    border: none;
    width: 40px;
    padding: 0px;
    text-align: center;
  }
  .dateInput p{
    padding: 0px;
  }
  .dateInput input:first-of-type{
    width: 35px;
  }
  .dateInput input:last-of-type{
    width: 50px;
  }
  .dateInput input:focus {
    outline:none;
  }

  .hourInput{
    display: flex;
    border: 1px solid grey;
    font-size: 20px;
    padding: 10px;
  }
  .hourInput input{
    border: none;
    width: 40px;
    padding: 0px;
    text-align: center;
  }
  .hourInput input:first-of-type{
    width: 35px;
  }
  .hourInput p{
    padding: 0px;
    margin-top: 1px;
  }
  .hourInput input:focus {
    outline:none;
  }
  .flex-date-time{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .flex-date-time label{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 0;
  }
  .flex-date-time p{
    padding: 0;
  }
  .flex-date-time p:last-of-type{
    width: 100px;
  }
  .ticketsFree{
    width: 200px;
  }
  .date-time-cols{
    width: 48%;
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1300px){
    .flex-date-time{
      display: block;
    }
    .date-time-cols{
      width: 100%;
      justify-content: start;
    }
    .addDateBtn::before{
      content: '+';
    }
    .addDateBtn{
      width: 50px;
    }
    .datesForm{
      width: 70%;
      display: flex;
    }
    .datesForm .flex-date-time{
      display: flex;
      justify-content: space-between;
    }
  }
  @media only screen and (max-width: 1120px){
    .datesForm{
      width: 80%;
      display: flex;
    }
  }
  @media only screen and (max-width: 1000px){
    .datesForm{
      width: 90%;
      display: flex;
    }
  }
  @media only screen and (max-width: 900px){
    .table-container{
      width: 100%;
      overflow: auto;
      margin-left: 0px;
      margin-right: 0px;  
    }
    .flex-event-details{
      display: block;
    }
    .flex-event-details img {
      display: none;
    }
    .flex-event-details .event-col{
      border: none;
      width: 100%;
    }
    .flex-event-details .event-col:first-of-type{
      border-bottom: 1px solid grey;
      padding-bottom: 20px;
    }
    .buyTicketBtn{
      margin: 0;
    }
    .newBtn .button{
      margin-right: 0px;
    }
    .event-form, .datesForm{
      width: 100%;
      padding: 0px;
    }
    .newEvent h3{
      padding: 0;
    }
    .detail-container{
      margin: 0;
      margin-bottom: 50px;
    }

  }
  
</style>