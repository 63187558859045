<template>
  <div class="page">
    <div>
      <PageHandler/>
    </div> 
  </div>
</template>
<script>
import PageHandler from './pages/PageHandler.vue'

export default {
  components: {
    PageHandler,
  },
  data() {
    return {

    }
  },
}
</script>
<style>

</style>