<template>
  <h3>Elthoro Events</h3>   
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style scoped>
  h3{
    width: 200px;
    margin: 0;
    padding: 20px;
    font-weight: bold;
    font-size: 22px;
    cursor: default;
  }
</style>