<template>

  <label class="text-danger"></label>  
  <h3 >Restore Password</h3> 
  <form method="post">  
    <label>Email</label>  
    <input type="text" name="email" class="form-control" />  

    <input type="submit" name="forgot" class="btn btn-info submit buttonColor" value="Send" />  
  </form> 

</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style scoped>
  .buttonColor{
    background-color: lightseagreen;
    border: none;
  }
  .buttonColor:hover{
    background-color: rgb(26, 139, 134);
  }
  .container .submit{
    display: block;
    text-align: center;
    width: 50%;
    font-weight: bold;
    margin: 35px auto;
    margin-bottom: 10px;
  }
</style>